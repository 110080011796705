import { faGift } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useState } from 'react'
import { Button } from '../Button'
import { StellaFilloutPopupEmbed } from '../StellaFilloutPopupEmbed'

export const GetCreditCard = ({ currentUser }) => {
  // Determine if the screen size is small (mobile)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [showForm, setShowForm] = useState(false)
  const formCloseHandler = () => {
    setShowForm(false)
  }

  return (
    <>
      {isSmall ? (
        <>
          <Button uiType="primary" onClick={() => setShowForm(true)}>
            Get $100 Credit
          </Button>
        </>
      ) : (
        <Paper style={{ padding: 14, backgroundColor: '#EBF6F8' }} elevation={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {/* Left Column: Icon */}
            <Box marginRight={2}>
              <FontAwesomeIcon icon={faGift} size="2x" style={{ color: '#006D77' }} />
            </Box>

            {/* Middle Column: Title and Subtitle */}
            <Box flexGrow={1} marginRight={2}>
              <Typography variant="button" style={{ color: '#006D77', marginBottom: 4 }}>
                Get Your $100 Credit
              </Typography>
            </Box>

            {/* Right Column: Button */}
            <Button uiType="primary" onClick={() => setShowForm(true)}>
              Get
            </Button>
          </Box>
        </Paper>
      )}

      {showForm && (
        <StellaFilloutPopupEmbed
          filloutId={process.env.REACT_APP_FILLOUT_100_CREDIT_FORM_ID}
          onClose={formCloseHandler}
          parameters={{
            email: currentUser?.attributes?.email,
            firstName: currentUser?.attributes?.profile?.firstName,
            lastName: currentUser?.attributes?.profile?.lastName,
            phone: currentUser?.attributes?.profile?.protectedData?.phoneNumber,
            userId: currentUser?.id?.uuid
          }}
        />
      )}
    </>
  )
}
